import { AsyncThunk, AnyAction } from "@reduxjs/toolkit";

// ref: https://redux-toolkit.js.org/api/createReducer#builderaddmatcher

type ThunkApiConfig = {
	serializedErrorType?: unknown;
};
type GenericAsyncThunk = AsyncThunk<unknown, unknown, ThunkApiConfig>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>;

function hasPrefix(action: AnyAction, prefix: string) {
	return action.type.startsWith(prefix);
}

export function isPendingAction(prefix = "") {
	return (action: AnyAction): action is PendingAction => {
		return hasPrefix(action, prefix) && action.type.endsWith("/pending");
	};
}

export function isRejectedAction(prefix = "") {
	return (action: AnyAction): action is RejectedAction => {
		return hasPrefix(action, prefix) && action.type.endsWith("/rejected");
	};
}

export function isFulfilledAction(prefix = "") {
	return (action: AnyAction): action is FulfilledAction => {
		return hasPrefix(action, prefix) && action.type.endsWith("/fulfilled");
	};
}
